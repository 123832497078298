<template>
  <div class="hfull">
    <!-- Pendaftaran Normal -->
    <b-container class="mt-5 pb-5">
      <b-row class="justify-content-center">
        <b-col md="12">
          <b-card>
            <b-container>
              <button class="btn btn-primary btn-sm" @click="goBack">
                <i class="ni ni-bold-left"></i> KEMBALI
              </button>
              <div class="header-body text-center mb-7">
                <b-row class="justify-content-center">
                  <b-col md="12">
                    <h2 class="text-black mt-4">PENDAFTARAN UJI KONEKTIFITAS UNTUK INDUSTRI</h2>
                  </b-col>
                </b-row>
              </div>
            </b-container>

            <b-container>
                <div>
                  <div class="form-group row pl-5 pr-5">
                    <label class="col-lg-3 col-form-label form-control-label">Peserta Uji</label>
                    <div class="col-lg-9">
                      <base-input name="Provinsi" rules="required">
                        <el-select v-model="personUjiKonek" filterable>
                          <el-option
                            v-for="option in ['Industri','Penyedia Alat dan Jasa']"
                            :key="option"
                            :label="option"
                            :value="option"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>



                  <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                    <b-form role="form" @submit.prevent="handleSubmit(newSubmitUjiKonektivitas)">
                        <!-- Informasi Umum -->
                        <div v-if="personUjiKonek == 'Penyedia Alat dan Jasa'">
                          <div class="pl-5 pr-5">
                              <hr />
                              <h3 class="mb-3">Informasi Umum</h3>
                              <div> 
                              <!-- Nama perusahaan -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nama Perusahaan</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nama Perusahaan"
                                    rules="required"
                                    v-model="userInput.namaPerusahaan"
                                  />
                                </div>
                              </div>

                               <!-- Jenis Industri -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Jenis Industri</label>
                                <div class="col-lg-9">
                                  <base-input name="Jenis Industri" rules="required">
                                    <el-select v-model="userInput.compType">
                                      <el-option
                                        v-for="option in provideSelect.companyType"
                                        :key="option.id"
                                        :label="option.name"
                                        :value="option.id"
                                      ></el-option>
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>
        
                              <!-- Nomor SIUP -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nomor SIUP</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nomor Siup"
                                    rules="required"
                                    v-model="userInput.nomorSiup"
                                  />
                                </div>
                              </div>
        
                              <!-- Nama penanggung jawab sesuai dengan SIUP -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nama Penanggung Jawab sesuai dengan SIUP</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nama Penanggung Jawab"
                                    rules="required"
                                    v-model="userInput.NamaPenanggungJawabSesuaiDenganSiup"
                                  />
                                </div>
                              </div>
        
                              <!-- Lampiran SIUP -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Lampiran SIUP</label>
                                <div class="col-lg-9">
                                  <base-input name="Lampiran SIUP">
                                    <file-input
                                      accept="application/pdf"
                                      ref="ujikonek_siup_file"
                                      @change="uploadFileBlob(file.uji_konek,'ujikonek_siup_file')"
                                    />
                                  </base-input>
                                  <small v-if="file.uji_konek.ujikonek_siup_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.uji_konek.ujikonek_siup_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
        
                              <!-- Alamat perusahaan -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Alamat Perusahaan</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Alamat Perusahaan"
                                    rules="required"
                                    v-model="userInput.AlamatPerusahaan"
                                  />
                                </div>
                              </div>
        
                              <!-- Alamat perusahaan -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Jalan</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Jalan"
                                    rules="required"
                                    v-model="userInput.jalan"
                                  />
                                </div>
                              </div>
        
                              <!-- Provinsi -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Provinsi</label>
                                <div class="col-lg-9">
                                  <base-input name="Provinsi" rules="required">
                                    <el-select
                                      v-model="userInput.Provinsi"
                                      @change="
                                        getCityDepentOnProvince(userInput.Provinsi)
                                      "
                                      filterable
                                    >
                                      <el-option
                                        v-for="option in provideSelect.provinsi"
                                        :key="option.id"
                                        :label="option.name"
                                        :value="option.id"
                                      />
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>
        
                              <!-- Kabupaten / Kota -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Kabupaten / Kota</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Kabupaten / Kota"
                                    rules="required"
                                  >
                                    <el-select v-model="userInput.Kabkot" filterable>
                                      <el-option
                                        v-for="option in provideSelect.kabupatenKota"
                                        :key="option.id"
                                        :label="option.name"
                                        :value="option.id"
                                      />
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>
        
                              <!-- Nomor Telepon Kantor -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nomor Telepon Kantor</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nomor Telepon Kantor"
                                    rules="required"
                                    v-model="userInput.NomorTeleponKantor"
                                    type="tel"
                                  />
                                </div>
                              </div>
        
                              <!-- Email kantor -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Email Kantor</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Email Kantor"
                                    rules="required"
                                    v-model="userInput.EmailKantor"
                                    type="email"
                                    autocomplete="username email"
                                  />
                                </div>
                              </div>
        
                              <!-- Nama direktur -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nama Direktur</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nama Direktur"
                                    rules="required"
                                    v-model="userInput.NamaDirektur"
                                  />
                                </div>
                              </div>
        
                              <!-- Nama penanggung jawab peserta uji -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nama Penanggung Jawab Peserta Uji</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nama Penanggung Jawab Peserta Uji"
                                    rules="required"
                                    v-model="userInput.NamaPenanggungJawabPesertaUji"
                                  />
                                </div>
                              </div>
        
                              <!-- Nomor Telepon penanggung jawab uji -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nomor Telepon Penanggung Jawab Uji</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nomor Telepon Penanggun Jawab Uji"
                                    rules="required"
                                    v-model="userInput.NomorTeleponPenanggungJawabUji"
                                    type="tel"
                                  />
                                </div>
                              </div>
        
                              <!-- Alamat email penanggung jawab uji -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Alamat Email Penanggung Jawab Uji</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Alamat Email Penanggun Jawab Uji"
                                    rules="required"
                                    v-model="userInput.AlamatEmailPenanggungJawabUji"
                                    type="email"
                                    autocomplete="username email"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Logger -->
                        <hr />
                        <div>
                          <div class="pl-5 pr-5">
                            <div v-for="(logger, index) in userInput.logger" :key="index">
                              <p class="font-weight-bold text-primary">
                                Data Logger #{{ index + 1 }}
                                <template v-if="index > 0">
                                  |
                                  <base-button
                                    class="bg-danger btn-sm"
                                    @click="removeLogger(index)"
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                    Hapus
                                  </base-button>
                                </template>
                              </p>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Brand</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Data Logger Brand ${index + 1}`"
                                    rules="required"
                                    v-model="userInput.logger[index].brand"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Model / Tipe</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Data Logger Model / Tipe ${index + 1}`"
                                    rules="required"
                                    v-model="userInput.logger[index].type"
                                  />
                                </div>
                              </div>

                              <hr v-if="userInput.logger.length > 1 && userInput.logger.length != index + 1"/>
                            </div>

                            <div class="d-flex justify-content-end  pb-4">
                              <base-button class="bg-dark w-25" @click="addLogger"><i class="fa fa-plus" aria-hidden="true"></i>
                                Tambah Data Logger
                              </base-button>
                            </div>
                          </div>
                        </div>

                      <div style="position: absolute;bottom: 0;left: 0;" class="w-100">
                        <div style="display: flex;justify-content: center;">
                          <button class="btn btn-primary mt-5 w-50 mb-5" type="submit">KIRIM UJI KONEKTIVITAS</button>
                        </div>
                      </div>
                    </b-form>
                  </validation-observer>

                   <!-- sensor -->
                   <hr>
                    <div style="margin-bottom: 150px;">
                      <div class="p-5" style="background-color: #ededed;">
                        <h3 class=" mb-3">Sensor</h3>
                        <div style="display: flex; justify-content: space-between">
                          <div>Sensor yang sudah didaftarkan</div>
                          <button @click="openSensorModal" class="btn btn-primary w-25">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            TAMBAH SENSOR</button>
                        </div>
                        <table class="table mt-5">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Nama Sensor</th>
                              <th>AKSI</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in sensorRegistered" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>{{ item.sensor_type }}</td>
                              <td>
                                <button class="btn btn-sm btn-warning" @click="populatedSensorForUpdate(item)">UBAH</button>
                                <button class="btn btn-sm btn-danger" @click="deleteSensorRegistered(item)">HAPUS</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </b-container>
           
            <!-- Sensor Add -->
            <Modal
              :show="showAddThirdWizard"
              modalContentClasses="popup-modal-backgrounds"
              modalClasses="modal-dialog-scrollable"
              size="xl"
            >
              <div>
                <div
                  id="announcement"
                  class="rounded overflow-hidden mdl-contentpopups"
                >
                  <div style="overflow-y: scroll; height: 650px">
                    <div class="p-5">
                      <div class="mb-5">Informasi Sensor</div>
                      <section>
                        <div class="px-4 pt-4">
                          <h1 class="sensor-header">
                            {{ sensor.name || "pilih tipe sensor" }}
                          </h1>
                        </div>
                        <div class="card-body pb-0">
                          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                            <b-form role="form" @submit.prevent="handleSubmit(submitSensor)">
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Tipe Sensor</label>
                                <div class="col-lg-9">
                                  <base-input name="Sensor Type" :rules="{required: true}">
                                    <el-select v-model="sensor.name">
                                      <el-option
                                        v-for="item in [
                                          'ph',
                                          'cod',
                                          'tss',
                                          'debit',
                                          'nh3n'
                                        ]"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                      />
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Brand</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Brand`"
                                    :rules="{required: true}"
                                    v-model="sensor.brand"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Probe</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Probe`"
                                    :rules="{required: true}"
                                    v-model="sensor.probe"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Range Pengukuran</label>
                                <div class="col-lg-9 d-flex px-0">
                                  <div class="col">
                                    <small>Minimal</small>
                                    <base-input
                                      :name="`Minimal Pengukuran`"
                                      :rules="{required: true}"
                                      v-model="sensor.min"
                                      type="number"
                                    />
                                  </div>
                                  <div class="col">
                                    <small>Maksimal</small>
                                    <base-input
                                      :name="`Maksimal Pengukuran`"
                                      rules="required"
                                      v-model="sensor.max"
                                      type="number"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Metode Pengukuran</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Metode Pengukuran`"
                                    :rules="{required: true, min: 3}"
                                    v-model="sensor.metodePengukuran"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Jadwal Kalibrasi</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Jadwal Kalibrasi pH `"
                                    :rules="{required: true}"
                                  >
                                    <el-select v-model="sensor.jadwalKalibrasi">
                                      <el-option
                                        v-for="bulan in 12"
                                        :key="bulan"
                                        :label="`${bulan} Bulan`"
                                        :value="`${bulan} Bulan`"
                                      />
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Metode Kalibrasi</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Metode Kalibrasi`"
                                    :rules="{required: true}"
                                    v-model="sensor.metodeKalibrasi"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Brosur Spesifikasi Sensor</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Brosur Spesifikasi Sensor`"
                                    :key="brosurSpesifikasiSensorFile"
                                  >
                                    <file-input
                                      accept="application/pdf"
                                      ref="ujikonek_brosur_file"
                                      @change="uploadFileBlob(file.uji_konek,'ujikonek_brosur_file')"
                                    />
                                  </base-input>
                                  <small v-if="file.uji_konek.ujikonek_brosur_file"
                                   class="d-block mt-3 text-justify">
                                    <a :href="getBlobsFile(file.uji_konek.ujikonek_brosur_file)"
                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Dukungan Brand Sensor</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Dukungan Brand Sensor`"
                                    :key="dukunganBrandSensorFile"
                                  >
                                    <file-input
                                      accept="application/pdf"
                                      ref="ujikonek_brand_path"
                                      @change="uploadFileBlob(file.uji_konek,'ujikonek_brand_path')"
                                    />
                                  </base-input>
                                  <small v-if="file.uji_konek.ujikonek_brand_path"
                                   class="d-block mt-3 text-justify">
                                      <a :href="getBlobsFile(file.uji_konek.ujikonek_brand_path)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>

                              <div style="display: flex; justify-content: center" class="mt-5">
                                <button class="btn btn-primary" type="submit" >
                                  DAFTAR SENSOR
                                </button>
                                <button
                                  class="btn btn-danger"
                                  @click="showAddThirdWizard = false"
                                  type="button"
                                >
                                  BATAL
                                </button>
                              </div>
                            </b-form>
                          </validation-observer>
                        </div>
                      </section>

                      
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <!-- Sensor Update -->
            <Modal
              :show="showUpdateThirdWizard"
              modalContentClasses="popup-modal-backgrounds"
              modalClasses="modal-dialog-scrollable"
              size="xl"
            >
              <div>
                <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
                  <div style="overflow-y: scroll; height: 650px">
                    <div class="p-5">
                      <div class="mb-5">Informasi Sensor</div>
                      <section>
                        <div class="px-4 pt-4">
                          <h1 class="sensor-header">
                            {{ sensor.name || "pilih tipe sensor" }}
                          </h1>
                        </div>
                        <div class="card-body pb-0">
                          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                            <b-form role="form" @submit.prevent="handleSubmit(sensorForUpdate)">
                              <div class="form-group row">
                            <label class="col-lg-3 col-form-label form-control-label">Tipe Sensor</label>
                            <div class="col-lg-9">
                              <base-input name="Permission Later">
                                <el-select v-model="sensor.name">
                                  <el-option
                                    v-for="item in [
                                      'ph',
                                      'cod',
                                      'tss',
                                      'debit',
                                    ]"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                  />
                                </el-select>
                              </base-input>
                            </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Brand</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Brand`"
                                    rules="required"
                                    v-model="sensor.brand"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Probe</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Probe`"
                                    rules="required"
                                    v-model="sensor.probe"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Range Pengukuran</label>
                                <div class="col-lg-9 d-flex px-0">
                                  <div class="col">
                                    <small>Minimal</small>
                                    <base-input
                                      :name="`Minimal Pengukuran`"
                                      rules="required"
                                      v-model="sensor.min"
                                      type="number"
                                    />
                                  </div>
                                  <div class="col">
                                    <small>Maksimal</small>
                                    <base-input
                                      :name="`Maksimal Pengukuran`"
                                      rules="required"
                                      v-model="sensor.max"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Metode Pengukuran</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Metode Pengukuran`"
                                    :rules="{required: true, min: 3}"
                                    v-model="sensor.metodePengukuran"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Jadwal Kalibrasi</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Jadwal Kalibrasi pH `"
                                    rules="required"
                                  >
                                    <el-select v-model="sensor.jadwalKalibrasi">
                                      <el-option
                                        v-for="bulan in 12"
                                        :key="bulan"
                                        :label="`${bulan} Bulan`"
                                        :value="`${bulan} Bulan`"
                                      />
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Metode Kalibrasi</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Metode Kalibrasi`"
                                    rules="required"
                                    v-model="sensor.metodeKalibrasi"
                                  />
                                </div>
                              </div>

                              <!-- <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Brosur Spesifikasi Sensor</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Brosur Spesifikasi Sensor`"
                                    :key="brosurSpesifikasiSensorFile"
                                  >
                                    <file-input
                                      accept="application/pdf"
                                      ref="ujikonek_brosur_file"
                                      @change="
                                        uploadSensor(
                                          'ujikonek_brosur_file',
                                          'ujikonek_brosur_file'
                                        )
                                      "
                                    />
                                  </base-input>
                                  <a :href="sensor.fileSpesifikasiSensor" target="blank">Lihat File Sebelumnya</a>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Dukungan Brand Sensor</label>
                                <div class="col-lg-9">
                                  <base-input
                                    :name="`Dukungan Brand Sensor`"
                                    :key="dukunganBrandSensorFile"
                                  >
                                    <file-input
                                      accept="application/pdf"
                                      ref="ujikonek_brand_path"
                                      @change="
                                        uploadSensor(
                                          'ujikonek_brand_path',
                                          'ujikonek_brand_path'
                                        )
                                      "
                                    />
                                  </base-input>
                                  <a :href="sensor.filedukunganBrandSensor" target="blank">Lihat File Sebelumnya</a>
                                </div>
                              </div> -->
                              <div style="display: flex; justify-content: center" class="mt-5">
                                <button class="btn btn-primary" type="submit">PERBAHARUI SENSOR</button>
                                <button class="btn btn-danger" type="button" @click="cancelUpdateSensor">BATAL</button>
                              </div>
                            </b-form>
                          </validation-observer>
                        </div>
                      </section>

                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import API from "@/api/base_url.js";
import FileInput from "@/components/Inputs/FileInput";
import Modal from "@/components/Modal.vue";
import { Select, Option } from "element-ui";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import baseURL from "@/api/base_url_backend.js";


const uploadFile = { path: "", upload: 0, progress: 0 };
const sensorField = {
  brand: null,
  probe: null,
  maxMeasurement: null,
  minMeasurement: null,
  measurementMethod: null,
  calibrationSchedule: null,
  calibrationMethod: null,
};

export default {
  name: "ujiKonektivitas",
  components: {
    FileInput,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mounted() {
    this.getSelectFormValue();
    this.getCompanyType();
  },
  data() {
    return {
      file: {
        uji_konek: {
          ujikonek_siup_file: '',
          ujikonek_brosur_file: '',
          ujikonek_brand_path: ''
        }
      },
      getSensorWillUpdate: "",
      sensorRegistered:[],
      personUjiKonek: 'Industri',
      dukunganBrandSensorFile: 0,
      brosurSpesifikasiSensorFile: 0,
      cartSensorRegistered: [],
      sensor: {
        name: "",
        brand: "",
        jadwalKalibrasi: "",
        max: "",
        metodeKalibrasi: "",
        metodePengukuran: "",
        min: "",
        probe: "",
        ujiKonekID: "",
        fileSpesifikasiSensor: "",
        filedukunganBrandSensor: "",
        idForUpdate: "",
      },
      showFirstWizard: false,
      showSecondWizard: false,
      showThirdWizard: false,
      showAddThirdWizard: false,
      showUpdateThirdWizard: false,
      userInput: {
        nomorSiup: "",
        namaPerusahaan: "",
        lampiranSiup: "",
        NamaPenanggungJawabSesuaiDenganSiup: "",
        AlamatPerusahaan: "",
        jalan: "",
        Provinsi: "",
        Kabkot: "",
        NomorTeleponKantor: "",
        EmailKantor: "",
        NamaPenanggungJawabPesertaUji: "",
        NomorTeleponPenanggungJawabUji: "",
        NamaDirektur: "",
        AlamatEmailPenanggungJawabUji: "",
        logger: [{ brand: null, type: null }],
        sensor: {
          ph: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          cod: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          tss: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          nh3n: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          debit: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
        },
        compType: ""
      },
      showAnnoncementModal: false,
      provinces: [],
      cities: [],
      region: [],
      uploading: false,
      disabled: false,
      form: {
        umum: {
          companyName: null,
          companyID: null,
          companyType: null,
          siupFile: { ...uploadFile },
          responsiblePerson: null,
          companyAddress: null,
          companyProvince: null,
          companyCity: null,
          companyPhone: null,
          companyMail: null,
          responsibleTestPerson: null,
          responsibleTestPhone: null,
          responsibleTestMail: null,
        },
        logger: [{ brand: null, type: null }],
        sensor: {
          ph: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          cod: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          tss: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          nh3n: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          debit: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
        },
      },
      jenisIndustri: null,
      provideSelect: {
        provinsi: [],
        kabupatenKota: [],
        companyType: []
      },
      formData: new FormData(),
      sensorData: new FormData(),
      idBundling: "",
    };
  },
  methods: {
    // start upload
    uploadFileBlob(form, field) {
        const fileInput = this.$refs[field];
        const file = fileInput.files[0];

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
        if (!allowedTypes.includes(file.type)) {
          Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
          return;
        }

        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        this.isLoading = true;
        API.post(`blobs/upload/public`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            let data = res.data.data;
            let value = data.id;
            this.$set(form, field, value);
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
          });
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    resetFileInput(ref) {
    if (this.$refs[ref]) {
      // this.$refs.lulus_uji_file_id.files = []
      this.$refs[ref].files = []
      const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
      inputElement.value = ''
    }
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    },
    // end of upload
    newSubmitUjiKonektivitas() {
      Swal.fire({
        title: "Kirim UjiKonektivitas?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "KIRIM",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          // make new structure of Logger
          let loggerTmp = []
          for (let i = 0; i < this.userInput.logger.length; i++) {
            loggerTmp.push({
              brand: this.userInput.logger[i].brand,
              id: "",
              type: this.userInput.logger[i].type,
              uid: ""
            })
          }

           // make new structure of Sensor
          let sensorTmp = [{ph:[],cod:[],debit:[],tss:[],nh3n:[]}]
          for (let i = 0; i < this.sensorRegistered.length; i++) {
            if (this.sensorRegistered[i].sensor_type == 'ph') {
              sensorTmp[0].ph.push({
                "brand": this.sensorRegistered[i].brand,
                "brochure_file_id": this.sensorRegistered[i].brosur_file,
                "calibration_method": this.sensorRegistered[i].metode_kalibrasi,
                "calibration_schedule": this.sensorRegistered[i].jadwal_kalibrasi,
                "id": "",
                "max_measurement": +this.sensorRegistered[i].max,
                "measurement_method": this.sensorRegistered[i].metode_pengukuran,
                "min_measurement": +this.sensorRegistered[i].min,
                "probe": this.sensorRegistered[i].probe,
                "support_file_id": this.sensorRegistered[i].support_file
              })
            }

            if (this.sensorRegistered[i].sensor_type == 'cod') {
              sensorTmp[0].cod.push({
                "brand": this.sensorRegistered[i].brand,
                "brochure_file_id": this.sensorRegistered[i].brosur_file,
                "calibration_method": this.sensorRegistered[i].metode_kalibrasi,
                "calibration_schedule": this.sensorRegistered[i].jadwal_kalibrasi,
                "id": "",
                "max_measurement": +this.sensorRegistered[i].max,
                "measurement_method": this.sensorRegistered[i].metode_pengukuran,
                "min_measurement": +this.sensorRegistered[i].min,
                "probe": this.sensorRegistered[i].probe,
                "support_file_id": this.sensorRegistered[i].support_file
              })
            }

            if (this.sensorRegistered[i].sensor_type == 'debit') {
              sensorTmp[0].debit.push({
                "brand": this.sensorRegistered[i].brand,
                "brochure_file_id": this.sensorRegistered[i].brosur_file,
                "calibration_method": this.sensorRegistered[i].metode_kalibrasi,
                "calibration_schedule": this.sensorRegistered[i].jadwal_kalibrasi,
                "id": "",
                "max_measurement": +this.sensorRegistered[i].max,
                "measurement_method": this.sensorRegistered[i].metode_pengukuran,
                "min_measurement": +this.sensorRegistered[i].min,
                "probe": this.sensorRegistered[i].probe,
                "support_file_id": this.sensorRegistered[i].support_file
              })
            }

            if (this.sensorRegistered[i].sensor_type == 'tss') {
              sensorTmp[0].tss.push({
                "brand": this.sensorRegistered[i].brand,
                "brochure_file_id": this.sensorRegistered[i].brosur_file,
                "calibration_method": this.sensorRegistered[i].metode_kalibrasi,
                "calibration_schedule": this.sensorRegistered[i].jadwal_kalibrasi,
                "id": "",
                "max_measurement": +this.sensorRegistered[i].max,
                "measurement_method": this.sensorRegistered[i].metode_pengukuran,
                "min_measurement": +this.sensorRegistered[i].min,
                "probe": this.sensorRegistered[i].probe,
                "support_file_id": this.sensorRegistered[i].support_file
              })
            }

            if (this.sensorRegistered[i].sensor_type == 'nh3n') {
              sensorTmp[0].nh3n.push({
                "brand": this.sensorRegistered[i].brand,
                "brochure_file_id": this.sensorRegistered[i].brosur_file,
                "calibration_method": this.sensorRegistered[i].metode_kalibrasi,
                "calibration_schedule": this.sensorRegistered[i].jadwal_kalibrasi,
                "id": "",
                "max_measurement": +this.sensorRegistered[i].max,
                "measurement_method": this.sensorRegistered[i].metode_pengukuran,
                "min_measurement": +this.sensorRegistered[i].min,
                "probe": this.sensorRegistered[i].probe,
                "support_file_id": this.sensorRegistered[i].support_file
              })
            }
          }


          let payload = {}

          if (this.personUjiKonek == 'Industri') {
            payload = {
              is_vendor: "0",
              loggers: loggerTmp,
              other_comp: {},
              sensors: sensorTmp[0]
            }
          } else {
            payload = {
              is_vendor: "1",
              loggers: loggerTmp,
              other_comp: {
                comp_address: this.userInput.AlamatPerusahaan,
                comp_direktur: this.userInput.NamaDirektur,
                comp_email: this.userInput.EmailKantor,
                comp_name: this.userInput.namaPerusahaan,
                comp_phone: this.userInput.NomorTeleponKantor,
                comp_street: this.userInput.jalan,
                comp_type_id: this.userInput.compType,
                id: "",
                kabkot_id: this.userInput.Kabkot,
                // koneksi_file_id: "/path/to/koneksi",
                prov_id: this.userInput.Provinsi,
                siup_file_id: this.file.uji_konek.ujikonek_siup_file,
                siup_no: this.userInput.nomorSiup,
                siup_pj: this.userInput.NamaPenanggungJawabSesuaiDenganSiup,
                user_email: this.userInput.AlamatEmailPenanggungJawabUji,
                user_name: this.userInput.NamaPenanggungJawabPesertaUji,
                user_phone: this.userInput.NomorTeleponPenanggungJawabUji
              },
              sensors: sensorTmp[0]
            }
          }

          if (this.sensorRegistered.length == 0) {
            Swal.fire("Sensor belum diisi", "Anda harus mengisi data sensor terlebhi dahulu", "error");
          } else {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };
  
            // this.resetForm()


            API.post(`uji-konek`, payload, { headers })
              .then(({ data: content }) => {
                Swal.fire("Uji Konektivitas berhasil dikirim!", "", "success");
                this.$router.push("/uji-konektivitas");
              })
              .catch((err) => {
                Swal.fire("Pendaftaran Uji Kokentivitas Gagal", "Harap Periksa Koneksi Internet Anda", "error");
              });
          }


        }
      });
    },
    submitSensor() {
      Swal.fire({
        title: "Tambah Sensor ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          this.sensorRegistered.push({
            id: (this.sensorRegistered.length + 1),
            brand:this.sensor.brand,
            jadwal_kalibrasi:this.sensor.jadwalKalibrasi,
            max:this.sensor.max + "",
            metode_kalibrasi:this.sensor.metodeKalibrasi,
            metode_pengukuran:this.sensor.metodePengukuran,
            min:this.sensor.min + "",
            probe:this.sensor.probe,
            sensor_type:this.sensor.name,
            brosur_file:this.file.uji_konek.ujikonek_brosur_file,
            support_file:this.file.uji_konek.ujikonek_brand_path
          })
            this.resetSensorPayload()
            this.showAddThirdWizard = false;
        }
      });
    },
    resetSensorPayload() {
        this.sensor.brand = ""
        this.sensor.jadwalKalibrasi = ""
        this.sensor.max = ""
        this.sensor.metodeKalibrasi = ""
        this.sensor.metodePengukuran = ""
        this.sensor.min = ""
        this.sensor.probe = ""
        this.sensor.name = ""
        this.file.uji_konek.ujikonek_brosur_file = ""
        this.file.uji_konek.ujikonek_brand_path = ""

        this.dukunganBrandSensorFile++;
        this.brosurSpesifikasiSensorFile++;
    },
    populatedSensorForUpdate(row) {
      this.getSensorWillUpdate = row.id
      this.sensor.brand = row.brand
      this.sensor.jadwalKalibrasi = row.jadwal_kalibrasi
      this.sensor.max = row.max
      this.sensor.metodeKalibrasi = row.metode_kalibrasi
      this.sensor.metodePengukuran = row.metode_pengukuran
      this.sensor.min = row.min
      this.sensor.probe = row.probe
      this.sensor.name = row.sensor_type
      this.showUpdateThirdWizard = true
    },
    sensorForUpdate() {
      let indexToUpdate = this.sensorRegistered.findIndex(
        (tableRow) => tableRow.id === this.getSensorWillUpdate
      );

      Swal.fire({
        title: "Perbaharui Sensor ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          this.sensorRegistered[indexToUpdate].brand = this.sensor.brand
          this.sensorRegistered[indexToUpdate].jadwal_kalibrasi = this.sensor.jadwalKalibrasi
          this.sensorRegistered[indexToUpdate].max = this.sensor.max
          this.sensorRegistered[indexToUpdate].metode_kalibrasi = this.sensor.metodeKalibrasi
          this.sensorRegistered[indexToUpdate].metode_pengukuran = this.sensor.metodePengukuran
          this.sensorRegistered[indexToUpdate].min = this.sensor.min
          this.sensorRegistered[indexToUpdate].probe = this.sensor.probe
          this.sensorRegistered[indexToUpdate].sensor_type = this.sensor.name


          this.dukunganBrandSensorFile++;
          this.brosurSpesifikasiSensorFile++;
          this.showUpdateThirdWizard = false
        }
      });
    },
    deleteSensorRegistered(row) {
      Swal.fire({
        title: `Hapus Sensor ${row.sensor_type} ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let indexToDelete = this.sensorRegistered.findIndex(
            (tableRow) => tableRow.sensor_type === row.sensor_type
          );
          if (indexToDelete >= 0) {
            this.sensorRegistered.splice(indexToDelete, 1);
          }
    
          Swal.fire({
            title: "Berhasil Menghapus",
            text: "Sensor telah dihapus dari Pendaftaran Uji Konektivitas",
            icon: "success",
          });
        }
      });
    },
    getSelectFormValue() {
      API.get(`provinces`)
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCityDepentOnProvince(data) {
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyType() {
      API.get("comp-types")
        .then(({ data: content }) => {
          this.provideSelect.companyType = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // new
    openSensorModal() {
      this.showAddThirdWizard = true;
    },
    goBack() {
      this.$router.push("/uji-konektivitas");
    },
   
    submitLogger() {
      Swal.fire({
        title: "Sudah Selesai mengisi Logger ?",
        text: "Jika sudah , akan melanjutkan pengisian sensor",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SUDAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = [];
          for (let i = 0; i < this.userInput.logger.length; i++) {
            payload.push({
              brand: this.userInput.logger[i].brand,
              type: this.userInput.logger[i].type,
              uji_konektivitas_id: this.idBundling,
            });
          }

          // Hit
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.post(`companies/uji-konektivitas/loggers`, payload, { headers })
            .then(({ data: content }) => {
              this.resetForm();
              Swal.fire("Logger Berhasil didaftarkan", "", "success");

              this.showSecondWizard = false;

              setTimeout(() => {
                this.showThirdWizard = true;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "gagal mendaftarkan", "error");
            });

          this.showSecondWizard = false;
        }
      });
    },
    getSensorRegistered() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `companies/uji-konektivitas/sensors?uji_konektivitas_id=${this.idBundling}`,
        { headers }
      )
        .then(({ data: content }) => {
          if (content.data == null) {
            this.cartSensorRegistered = []
          } else {
            this.cartSensorRegistered = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Gagal", "gagal mendapatkan daftar sensor", "error");
        });
    },
    cancelUpdateSensor() {
      this.showUpdateThirdWizard = false;
      this.sensor.name = "";
      this.sensor.brand = "";
      this.sensor.probe = "";
      this.sensor.min = "";
      this.sensor.max = "";
      this.sensor.metodePengukuran = "";
      this.sensor.jadwalKalibrasi = "";
      this.sensor.metodeKalibrasi = "";
      this.sensor.fileSpesifikasiSensor = "";
      this.sensor.filedukunganBrandSensor = "";
      this.sensor.idForUpdate = "";
      this.sensorData = new FormData()
    },
    closeModalAddSensor() {
      Swal.fire({
        title: "Apakah Anda Yakin?",
        text: "Jika Menekan selesai , seluruh pendaftaran uji konektivitas sudah dinyatakan selesai",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SELESAI",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          this.showThirdWizard = false;
          Swal.fire("Pendaftaran Uji Konek Selesai", "", "success");
          this.cartSensorRegistered = [];
        }
      });
    },
    resetForm() {
      this.sensor = {
        name: "",
        brand: "",
        jadwalKalibrasi: "",
        max: "",
        metodeKalibrasi: "",
        metodePengukuran: "",
        min: "",
        probe: "",
        ujiKonekID: "",
      };

      this.userInput = {
        nomorSiup: "",
        namaPerusahaan: "",
        lampiranSiup: "",
        NamaPenanggungJawabSesuaiDenganSiup: "",
        AlamatPerusahaan: "",
        jalan: "",
        Provinsi: "",
        Kabkot: "",
        NomorTeleponKantor: "",
        EmailKantor: "",
        NamaPenanggungJawabPesertaUji: "",
        NomorTeleponPenanggungJawabUji: "",
        NamaDirektur: "",
        AlamatEmailPenanggungJawabUji: "",
        logger: [{ brand: null, type: null }],
        sensor: {
          ph: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          cod: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          tss: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          nh3n: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
          debit: [
            {
              ...sensorField,
              brochureFile: { ...uploadFile },
              supportFile: { ...uploadFile },
            },
          ],
        },
        compType:""
      };
    },
    addLogger() {
      this.userInput.logger.push({
        brand: null,
        type: null,
      });
    },
    removeLogger(index) {
      this.userInput.logger.splice(index, 1);
    },
    addSensor(unit) {
      this.userInput.sensor[unit].push({
        ...sensorField,
        brochureFile: { ...uploadFile },
        supportFile: { ...uploadFile },
      });
    },
    removeSensor(unit, index) {
      this.userInput.sensor[unit].splice(index, 1);
    },
    // uploadFile(category, field) {
    //   if (this.formData.get(field) !== null) {
    //     this.formData.delete(field)
    //     this.formData.append(field, this.$refs[field].files[0]);
    //   } else {
    //     this.formData.append(field, this.$refs[field].files[0]);
    //   }
    // },
    uploadSensor(category, field) {
      if (this.sensorData.get(field) !== null) {
        this.sensorData.delete(field)
        this.sensorData.append(field, this.$refs[field].files[0]);
      } else {
        this.sensorData.append(field, this.$refs[field].files[0]);
      }
    },
  },
};
</script>

